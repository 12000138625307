<template>
  <div>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="任务开始时间">
                <a-range-picker
                  style="width: 100%"
                  v-model="daterangeTaskStartTime"
                  valueFormat="YYYY-MM-DD"
                  format="YYYY-MM-DD"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="任务结束时间">
                <a-range-picker
                  style="width: 100%"
                  v-model="daterangeTaskEndTime"
                  valueFormat="YYYY-MM-DD"
                  format="YYYY-MM-DD"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar refresh :loading="loading" @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:vehicleTaskRecord:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button type="dashed" shape="circle" :loading="loading" :style="{ float: 'right' }" icon="reload" @click="getList" /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <!-- <create-form
        ref="createForm"
        :statusOptions="statusOptions"
        :stateOptions="stateOptions"
        :taskStateOptions="taskStateOptions"
        @ok="getList"
      /> -->
      <!-- 数据展示 -->
      <a-table
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="taskStartTime" slot-scope="text, record">
          {{ parseTime(record.taskStartTime) }}
        </span>
        <span slot="taskEndTime" slot-scope="text, record">
          {{ parseTime(record.taskEndTime) }}
        </span>
        <span slot="taskState" slot-scope="text, record">
          {{ taskStateFormat(record) }}
        </span>
        <span slot="state" slot-scope="text, record">
          {{ stateFormat(record) }}
        </span>
        <span slot="taskProgress" slot-scope="text, record">
          <a-progress :percent="record.taskProgress" :status="getTaskProgressStatus(record)"></a-progress>
        </span>
        <span slot="taskStart" slot-scope="text, record"> {{ taskStartFormat(record) }} </span>state
        <span slot="operation" slot-scope="text, record">
          <template v-if="[-1, 0, 1, 2, 3, 4].indexOf(record.taskFinishedFlag) >= 0">
            <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicleTaskRecord:edit']" />
            <a @click="handleNavigateTo(record)" v-hasPermi="['tenant:iot:vehicleTaskRecord:edit']">
              <a-icon type="edit" />任务报告
            </a>
          </template>

          <template v-if="record.taskState == 4">
            <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicleTaskRecord:edit']" />
            <a @click="handlePauseTask(record.id)" v-hasPermi="['tenant:iot:vehicleTaskRecord:edit']">
              <a-icon type="edit" />暂停
            </a>
            <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicleTaskRecord:edit']" />
            <a @click="handleStopTask(record.id)" v-hasPermi="['tenant:iot:vehicleTaskRecord:edit']">
              <a-icon type="edit" />停止
            </a>
          </template>
          <template v-if="record.taskState == 1">
            <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicleTaskRecord:edit']" />
            <a @click="handleStopTask(record.id)" v-hasPermi="['tenant:iot:vehicleTaskRecord:edit']">
              <a-icon type="edit" />停止
            </a>
          </template>
          <template v-if="record.taskState == 1">
            <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicleTaskRecord:edit']" />
            <a @click="handleResumeTask(record.id)" v-hasPermi="['tenant:iot:vehicleTaskRecord:edit']">
              <a-icon type="edit" />继续
            </a>
          </template>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>

    <vxe-modal
      v-model="isSendingTask"
      :title="sendTaskTitle"
      width="500"
      height="300"
      :show-zoom="false"
      resize
      @close="handleCloseSendTask"
    >
      <a-spin :tip="tips" :spinning="spinning" v-if="spinning">
        <div class="spin-content"></div>
      </a-spin>
      <div v-else class="task-result">{{ tips }}</div>
    </vxe-modal>
  </div>
</template>

<script>
import { listVehicleTaskRecord, delVehicleTaskRecord, exportVehicleTaskRecord } from '@/api/iot/vehicleTaskRecord'
import CreateForm from './modules/CreateForm'
import TaskBase from '@/views/monitor/monitorForm/vehicle/funcList/modules/VehicleTask/TaskBase'
import { Progress } from 'ant-design-vue'
export default {
  mixins: [TaskBase],
  name: 'VehicleTaskRecord',
  components: {
    CreateForm,
    AProgress: Progress
  },
  props: {
    breadcrumb: {
      type: Boolean,
      default: true
    },
    taskId: {
      type: String,
      default: ''
    },
    // 参数对象，包含当前组件所需要用到的props参数
    params: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 任务开始时间时间范围
      daterangeTaskStartTime: [],
      // 任务结束时间时间范围
      daterangeTaskEndTime: [],
      // 是否删除 0否1是字典
      statusOptions: [],
      // 查询参数
      queryParam: {
        taskStartTime: null,
        taskEndTime: null,
        raTaskId: this.taskId ? this.taskId : null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '中控任务名称',
        //   dataIndex: 'taskName',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '任务开始时间',
          dataIndex: 'taskStartTime',
          scopedSlots: { customRender: 'taskStartTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '任务结束时间',
          dataIndex: 'taskEndTime',
          scopedSlots: { customRender: 'taskEndTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '地图ID',
        //   dataIndex: 'mapId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '地图版本',
        //   dataIndex: 'mapVersion',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '任务进度',
          dataIndex: 'taskProgress',
          scopedSlots: { customRender: 'taskProgress' },
          ellipsis: true,
          align: 'center',
          width: 150
        },
        // {
        //   title: '运行状态',
        //   dataIndex: 'state',
        //   scopedSlots: { customRender: 'state' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '任务状态',
          dataIndex: 'taskState',
          scopedSlots: { customRender: 'taskState' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      isSendingTask: false,
      sendTaskTitle: '',
      stateOptions: [],
      vehicleRemoteCtlResultOptions: [],
      taskStateOptions: [],
      isShowBreadcrumb: this.breadcrumb,
      isGetData: false,
      timer: null
    }
  },
  filters: {},
  created() {
    this.isGetData = true
    this.getData()
  },
  computed: {},
  watch: {
    taskId(newVal, oldVal) {
      console.log('taskId', newVal)
      this.queryParam.raTaskId = newVal
      this.getList()
    }
  },
  methods: {
    loopGetList() {
      this.timer && clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.getList()
      }, 5000)
    },
    async getData() {
      await this.getDicts('iot_del_yesno').then((response) => {
        this.statusOptions = response.data
      })
      // 车辆任务运行状态
      await this.getDicts('iot_task_state').then((response) => {
        this.stateOptions = response.data
      })
      // 车端回传结果状态
      await this.getDicts('iot_vehicle_remoteCtlResult').then((response) => {
        this.vehicleRemoteCtlResultOptions = response.data
      })
      // 车端任务状态
      await this.getDicts('iot_task_taskState').then((response) => {
        this.taskStateOptions = response.data
      })
      this.getListData()
    },
    // 获取列表数据
    getListData() {
      // 用于容器中集成弹窗组件
      if (this.params && typeof this.params === 'object') {
        this.queryParam.taskSchedulerId = this.params.taskId
        this.isShowBreadcrumb = this.params.breadcrumb
        // 如果是查看返回，则不需要重新加载页面
        if (this.params.navigateType === 'back') {
          return
        }
      }
      this.getList()
      this.loopGetList()
    },
    taskStateFormat(row) {
      return this.selectDictLabel(this.taskStateOptions, row.taskState)
    },
    taskStartFormat(row) {
      return this.selectDictLabel(this.vehicleRemoteCtlResultOptions, row.taskStart)
    },
    stateFormat(row) {
      return this.selectDictLabel(this.stateOptions, row.state)
    },
    /** 查询任务下发记录列表 */
    getList() {
      this.loading = true
      this.queryParam.params = {}
      if (
        this.daterangeTaskStartTime !== null &&
        this.daterangeTaskStartTime !== '' &&
        this.daterangeTaskStartTime.length !== 0
      ) {
        this.queryParam.params['beginTaskStartTime'] = this.daterangeTaskStartTime[0]
        this.queryParam.params['endTaskStartTime'] = this.daterangeTaskStartTime[1]
      }
      if (
        this.daterangeTaskEndTime !== null &&
        this.daterangeTaskEndTime !== '' &&
        this.daterangeTaskEndTime.length !== 0
      ) {
        this.queryParam.params['beginTaskEndTime'] = this.daterangeTaskEndTime[0]
        this.queryParam.params['endTaskEndTime'] = this.daterangeTaskEndTime[1]
      }
      listVehicleTaskRecord(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
        this.isGetData = false
      })
    },
    // 是否删除 0否1是字典翻译
    statusFormat(row, column) {
      return this.selectDictLabel(this.statusOptions, row.status)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeTaskStartTime = []
      this.daterangeTaskEndTime = []
      this.queryParam = {
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delVehicleTaskRecord(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportVehicleTaskRecord(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    toEnd(infoType, result) {
      this.$loading.hide()
      let myModal = this.$success
      if (infoType === 'warn') {
        myModal = this.$warning
      } else if (infoType === 'error') {
        myModal = this.$error
      }
      myModal({
        title: '提示',
        content: result,
        zIndex: '10000',
        onOk: () => {
          this.clearTimeoutEx()
          // this.tips = result
          // this.spinning = false
          // this.isSendingTask = false
          // 如果设备操作成功，则通过上级页面刷新
          if (infoType === 'success') {
            this.$emit('update')
          }
          this.getList()
        }
      })
    },
    getTaskProgressStatus(record) {
      if (record.taskState === '1' || record.taskState === '3') {
        return 'exception'
      } else if (record.taskState === '5') {
        return 'success'
      } else {
        return ''
      }
    },
    handleNavigateTo(record) {
      console.log('handleNavigateTo', record)
      this.$emit('navigateTo', 'taskRecordDetail', {
        id: record.id,
        breadcrumb: false,
        statusOptions: this.statusOptions,
        stateOptions: this.stateOptions,
        taskStateOptions: this.taskStateOptions,
        taskTypeOptions: this.params.taskTypeOptions,
        parentTitle: record.vehicleId + '-' + record.taskName + '-'
      })
    }
  },
  beforeDestroy() {
    // console.log('ViewTaskRecord', 'beforeDestroy')
    // this.isClearTimeout = true
    // this.clearTimeoutEx()
  },
  activated() {
    // 页面激活
    console.log('taskRecord-activated', this.params)
    this.vehicleId = this.params.vehicleId
    // 如果是查看返回是不需要重新加载数据
    if (!this.isGetData) {
      this.getListData()
    }
  },
  deactivated() {
    console.log('taskRecord-deactivated', this.params)
    this.isClearTimeout = true
    this.clearTimeoutEx()
    this.timer && clearInterval(this.timer)
  }
}
</script>
