var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c(
            "div",
            { staticClass: "table-page-search-wrapper" },
            [
              _c(
                "a-form",
                { attrs: { layout: "inline" } },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 48 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { md: 8, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "任务开始时间" } },
                            [
                              _c("a-range-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  valueFormat: "YYYY-MM-DD",
                                  format: "YYYY-MM-DD",
                                  "allow-clear": "",
                                },
                                model: {
                                  value: _vm.daterangeTaskStartTime,
                                  callback: function ($$v) {
                                    _vm.daterangeTaskStartTime = $$v
                                  },
                                  expression: "daterangeTaskStartTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { md: 8, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "任务结束时间" } },
                            [
                              _c("a-range-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  valueFormat: "YYYY-MM-DD",
                                  format: "YYYY-MM-DD",
                                  "allow-clear": "",
                                },
                                model: {
                                  value: _vm.daterangeTaskEndTime,
                                  callback: function ($$v) {
                                    _vm.daterangeTaskEndTime = $$v
                                  },
                                  expression: "daterangeTaskEndTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { md: (!_vm.advanced && 8) || 24, sm: 24 } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "table-page-search-submitButtons",
                              style:
                                (_vm.advanced && {
                                  float: "right",
                                  overflow: "hidden",
                                }) ||
                                {},
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.handleQuery },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "search" } }),
                                  _vm._v("查询"),
                                ],
                                1
                              ),
                              _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-left": "8px" },
                                  on: { click: _vm.resetQuery },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "redo" } }),
                                  _vm._v("重置"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-toolbar",
            {
              attrs: { refresh: "", loading: _vm.loading, tid: "1" },
              on: { refreshQuery: _vm.getList },
            },
            [
              _c(
                "div",
                { staticClass: "table-operations" },
                [
                  _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["tenant:iot:vehicleTaskRecord:export"],
                          expression: "['tenant:iot:vehicleTaskRecord:export']",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.handleExport },
                    },
                    [
                      _c("a-icon", { attrs: { type: "download" } }),
                      _vm._v("导出 "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "a-table",
            {
              attrs: {
                size: _vm.tableSize,
                rowKey: "id",
                columns: _vm.columns,
                "data-source": _vm.list,
                "row-selection": {
                  selectedRowKeys: _vm.selectedRowKeys,
                  onChange: _vm.onSelectChange,
                },
                pagination: false,
              },
              scopedSlots: _vm._u([
                {
                  key: "taskStartTime",
                  fn: function (text, record) {
                    return _c("span", {}, [
                      _vm._v(
                        " " + _vm._s(_vm.parseTime(record.taskStartTime)) + " "
                      ),
                    ])
                  },
                },
                {
                  key: "taskEndTime",
                  fn: function (text, record) {
                    return _c("span", {}, [
                      _vm._v(
                        " " + _vm._s(_vm.parseTime(record.taskEndTime)) + " "
                      ),
                    ])
                  },
                },
                {
                  key: "taskState",
                  fn: function (text, record) {
                    return _c("span", {}, [
                      _vm._v(" " + _vm._s(_vm.taskStateFormat(record)) + " "),
                    ])
                  },
                },
                {
                  key: "state",
                  fn: function (text, record) {
                    return _c("span", {}, [
                      _vm._v(" " + _vm._s(_vm.stateFormat(record)) + " "),
                    ])
                  },
                },
                {
                  key: "taskProgress",
                  fn: function (text, record) {
                    return _c(
                      "span",
                      {},
                      [
                        _c("a-progress", {
                          attrs: {
                            percent: record.taskProgress,
                            status: _vm.getTaskProgressStatus(record),
                          },
                        }),
                      ],
                      1
                    )
                  },
                },
                {
                  key: "taskStart",
                  fn: function (text, record) {
                    return _c("span", {}, [
                      _vm._v(" " + _vm._s(_vm.taskStartFormat(record)) + " "),
                    ])
                  },
                },
                {
                  key: "operation",
                  fn: function (text, record) {
                    return _c(
                      "span",
                      {},
                      [
                        [-1, 0, 1, 2, 3, 4].indexOf(record.taskFinishedFlag) >=
                        0
                          ? [
                              _c("a-divider", {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: [
                                      "tenant:iot:vehicleTaskRecord:edit",
                                    ],
                                    expression:
                                      "['tenant:iot:vehicleTaskRecord:edit']",
                                  },
                                ],
                                attrs: { type: "vertical" },
                              }),
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: [
                                        "tenant:iot:vehicleTaskRecord:edit",
                                      ],
                                      expression:
                                        "['tenant:iot:vehicleTaskRecord:edit']",
                                    },
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleNavigateTo(record)
                                    },
                                  },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "edit" } }),
                                  _vm._v("任务报告 "),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        record.taskState == 4
                          ? [
                              _c("a-divider", {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: [
                                      "tenant:iot:vehicleTaskRecord:edit",
                                    ],
                                    expression:
                                      "['tenant:iot:vehicleTaskRecord:edit']",
                                  },
                                ],
                                attrs: { type: "vertical" },
                              }),
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: [
                                        "tenant:iot:vehicleTaskRecord:edit",
                                      ],
                                      expression:
                                        "['tenant:iot:vehicleTaskRecord:edit']",
                                    },
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePauseTask(record.id)
                                    },
                                  },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "edit" } }),
                                  _vm._v("暂停 "),
                                ],
                                1
                              ),
                              _c("a-divider", {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: [
                                      "tenant:iot:vehicleTaskRecord:edit",
                                    ],
                                    expression:
                                      "['tenant:iot:vehicleTaskRecord:edit']",
                                  },
                                ],
                                attrs: { type: "vertical" },
                              }),
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: [
                                        "tenant:iot:vehicleTaskRecord:edit",
                                      ],
                                      expression:
                                        "['tenant:iot:vehicleTaskRecord:edit']",
                                    },
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleStopTask(record.id)
                                    },
                                  },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "edit" } }),
                                  _vm._v("停止 "),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        record.taskState == 1
                          ? [
                              _c("a-divider", {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: [
                                      "tenant:iot:vehicleTaskRecord:edit",
                                    ],
                                    expression:
                                      "['tenant:iot:vehicleTaskRecord:edit']",
                                  },
                                ],
                                attrs: { type: "vertical" },
                              }),
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: [
                                        "tenant:iot:vehicleTaskRecord:edit",
                                      ],
                                      expression:
                                        "['tenant:iot:vehicleTaskRecord:edit']",
                                    },
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleStopTask(record.id)
                                    },
                                  },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "edit" } }),
                                  _vm._v("停止 "),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        record.taskState == 1
                          ? [
                              _c("a-divider", {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: [
                                      "tenant:iot:vehicleTaskRecord:edit",
                                    ],
                                    expression:
                                      "['tenant:iot:vehicleTaskRecord:edit']",
                                  },
                                ],
                                attrs: { type: "vertical" },
                              }),
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: [
                                        "tenant:iot:vehicleTaskRecord:edit",
                                      ],
                                      expression:
                                        "['tenant:iot:vehicleTaskRecord:edit']",
                                    },
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleResumeTask(record.id)
                                    },
                                  },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "edit" } }),
                                  _vm._v("继续 "),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  },
                },
              ]),
            },
            [_vm._v("state ")]
          ),
          _c("a-pagination", {
            staticClass: "ant-table-pagination",
            attrs: {
              "show-size-changer": "",
              "show-quick-jumper": "",
              current: _vm.queryParam.pageNum,
              total: _vm.total,
              "page-size": _vm.queryParam.pageSize,
              showTotal: function (total) {
                return "共 " + total + " 条"
              },
            },
            on: {
              showSizeChange: _vm.onShowSizeChange,
              change: _vm.changeSize,
            },
          }),
        ],
        1
      ),
      _c(
        "vxe-modal",
        {
          attrs: {
            title: _vm.sendTaskTitle,
            width: "500",
            height: "300",
            "show-zoom": false,
            resize: "",
          },
          on: { close: _vm.handleCloseSendTask },
          model: {
            value: _vm.isSendingTask,
            callback: function ($$v) {
              _vm.isSendingTask = $$v
            },
            expression: "isSendingTask",
          },
        },
        [
          _vm.spinning
            ? _c(
                "a-spin",
                { attrs: { tip: _vm.tips, spinning: _vm.spinning } },
                [_c("div", { staticClass: "spin-content" })]
              )
            : _c("div", { staticClass: "task-result" }, [
                _vm._v(_vm._s(_vm.tips)),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }